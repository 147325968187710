import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { HubConnectionState } from '@aspnet/signalr/dist/esm/HubConnection';
import { AuthService } from '@app/shared/common/IDSVRAuthProviders/auth.service';
import { MyFinancialService } from '@app/shared/services/my-financial.service';
const WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS = 2000;

@Injectable({
    providedIn: 'root'
})
export class NotificationSignalRService {
    messageReceived$ = new Subject<string>();
    connectionEstablished$ = new BehaviorSubject<boolean>(false);
    notificationData$ = new Subject();
    singalrNotification = abp.setting.get('ServerRootAddress').indexOf('/') === abp.setting.get('ServerRootAddress').length - 1 ? 'signalr-notification' : '/signalr-notification';

    private hubConnection: HubConnection;

    constructor(private authService: AuthService, public _myFinancialService: MyFinancialService) {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }


    private createConnection() {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(abp.setting.get('ServerRootAddress') + this.singalrNotification, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }

    private startConnection() {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            return;
        }

        setTimeout(() => {
            this.hubConnection.start().then(
                (data: any) => {
                    console.log('Hub connection started');
                    this.hubConnection.invoke('onConnectedAsync', this.authService.user.profile.sub).then(() => {
                        console.log('connected async');
                        // Send the connectionId to controller
                    });
                    this.connectionEstablished$.next(true);
                },
                error => console.error(error)
            );
        }, WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS);
    }

    private registerOnServerEvents(): void {
        this.hubConnection.on('NotificationReceived', (data: any) => {
            if (data[0].includes('collectionId')) {
                let collectionId = data[0].split(':')[1];
                this._myFinancialService.queueTruIdFinancialDataSync(collectionId.trim());
            }
            console.log(data);
            this.notificationData$.next(data);
        });
    }
}
